import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { format_date, show_proper_words } from "../../utils/constant";
import { activity_logs_list_api } from "../../DAL/ActivityLogs/ActivityLogs";

export default function ActivityLogs() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [activityLogsData, setActivityLogsData] = useState([]);

  const get_activity_logs_list = async () => {
    setIsLoading(true);
    const result = await activity_logs_list_api();
    if (result.code === 200) {
      let activity_logs = result.activity_logs.map((activity_log) => {
        let users_type = show_proper_words(activity_log.user_info.user_type);
        let error_type = show_proper_words(activity_log.info_obj.type);
        let created_at = format_date(activity_log.createdAt);
        let user_name = activity_log.user_info.name;
        user_name += " (" + users_type + ")";

        return { ...activity_log, user_name, error_type, created_at };
      });
      setActivityLogsData(activity_logs);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "user_name", label: "User Name" },
    { id: "error_type", label: "Error Type" },
    { id: "created_at", label: "Created At" },
  ];

  useEffect(() => {
    get_activity_logs_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Activity Logs</h2>
                </div>
              </div>
              <CustomMUITable TABLE_HEAD={TABLE_HEAD} data={activityLogsData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
