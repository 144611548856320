import React, { useContext, useRef, useState } from "react";
import { useContentSetting } from "./ContentSetting";
import { useEffect } from "react";
import { tune_file, ended_call_tune } from "../assets";
import Peer from "peerjs";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";

let micError =
  "Microphone access is denied. Please grant permission in your browser settings.";
const constraints = { audio: true };

let cameraError =
  "Camera permission is denied. Please enable it in your browser settings.";

let peerConfig = {
  config: {
    iceServers: [{ url: "stun:stun.l.google.com:19302" }],
  },
};

const CreateClassRoom = React.createContext();
export const useClassRoom = () => useContext(CreateClassRoom);
export function ClassRoom({ children }) {
  const start_call_tune = new Audio(tune_file);
  const ended_call_file = new Audio(ended_call_tune);
  const isDisconnectingRef = useRef(false);
  const myStreamRef = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [myPeer, setMyPeer] = useState(null);
  const [myPeerID, setMyPeerID] = useState(null);
  const [myStream, setMyStream] = useState(null);
  const [studentStream, setStudentStream] = useState(null);
  const [lastCallID, setLastCallID] = useState(null);
  const [isIncomingCall, setIsIncomingCall] = useState(false);
  const [isCallAccepted, setIsCallAccepted] = useState(false);
  const [callUser, setCallUser] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [startCallTune, setStartCallTune] = useState(start_call_tune);
  const [endedCallTune, setEndedCallTune] = useState(ended_call_file);
  const [isUnavailable, setIsUnavailable] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [isSharedScreen, setIsSharedScreen] = useState(false);
  const [micOn, setMicOn] = useState(true);
  const { socket, userInfo, startedMeeting } = useContentSetting();
  const [studentPeerID, setStudentPeerID] = useState("");

  const handleGetSteam = async (is_screen_share) => {
    const mic = await navigator.permissions.query({ name: "microphone" });
    if (mic.state === "denied") {
      alert(micError);
      return false;
    }
    let stream = await navigator.mediaDevices.getUserMedia(constraints);
    if (is_screen_share === false && myStreamRef.current) {
      myStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (!micOn) {
      stream.getAudioTracks().forEach((track) => (track.enabled = false));
    }

    if (is_screen_share) {
      const camera = await navigator.permissions.query({ name: "camera" });
      if (camera.state === "denied") {
        alert(cameraError);
        return;
      }
      try {
        let screenStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        myStream.getTracks().forEach((track) => track.stop());
        const videoTrack = screenStream.getVideoTracks()[0];
        stream.addTrack(videoTrack);
        videoTrack.onended = () => {
          stream.getTracks().forEach((track) => track.stop());
          toggleScreenShare(false);
        };
      } catch (error) {
        return null;
      }
    }

    myStreamRef.current = stream;
    setMyStream(stream);
    return stream;
  };

  const handleStartCall = async (conversation_id, user) => {
    const stream = await handleGetSteam();
    if (!stream) return;
    var peer = new Peer(peerConfig);
    setMyPeer(peer);
    setSelectedUser(user);
    setCallUser(user._id);

    peer.on("open", (id) => {
      setMyPeerID(id);
      let callData = {
        to: user._id,
        conversation_id,
        type: "admin",
        sender_id: userInfo._id,
        peer_id: id,
      };
      socket.emit("user:call", callData);
    });

    peer.on("call", (call) => {
      call.answer(myStreamRef.current);
      call.on("stream", (remoteStream) => {
        setStudentStream(remoteStream);
        handleStopAudio();
        if (isDisconnectingRef.current) {
          let message = "Student Connection has been restored";
          enqueueSnackbar(message, { variant: "info" });
        }
        isDisconnectingRef.current = false;
        setIsDisconnecting(false);
        setIsIncomingCall(false);
        setIsCallAccepted(true);
      });
    });

    peer.on("error", (err) => {
      let logData = {
        user_info: userInfo,
        info_obj: err,
      };
      socket.emit("activity-log", logData);
      console.error("PeerJS Error:", err);
    });
  };

  const handleIncommingCall = async (data) => {
    const { from } = data;
    setCallUser(from);
    setIsIncomingCall(true);
  };

  const handleStopAudio = () => {
    startCallTune.pause();
    startCallTune.currentTime = 0;
  };

  const handleCallRinging = async ({ data }) => {
    const { to, last_call_id } = data;
    if (to === userInfo._id) {
      setLastCallID(last_call_id);
      startCallTune.play();
      startCallTune.addEventListener("ended", () => {
        startCallTune.play();
      });
    }
  };

  const handleCallAccepted = async ({ data }) => {
    const { to, peer_id } = data;
    if (to === userInfo._id) {
      setStudentPeerID(peer_id);
    }
  };

  const handleChangeMedia = async (data) => {
    const { media_type, media_status, to } = data;
    if (to !== userInfo._id) return;
    if (media_type === "is_paused") {
      setIsPaused(media_status);
    } else if (media_type === "is_muted") {
      setIsMuted(media_status);
    }
  };

  const handleRejoin = async () => {
    const { participants, _id } = startedMeeting;
    let user = participants.find((p) => p.participant_type === "student");
    let i_am = participants.find((p) => p.member === userInfo._id);
    if (user && i_am) {
      const { member, is_paused, is_screen_shared } = user;
      const { is_muted } = i_am;
      const stream = await handleGetSteam(is_screen_shared);
      if (!stream) return;

      if (is_muted) {
        stream.getAudioTracks().forEach((track) => (track.enabled = false));
        setMicOn(false);
      }
      var peer = new Peer(peerConfig);
      setMyPeer(peer);
      setCallUser(member);
      peer.on("open", (id) => {
        setMyPeerID(id);
        let callData = {
          to: member,
          from: userInfo._id,
          peer_id: id,
          last_call_id: _id,
        };
        socket.emit("user:rejoined", callData);
      });
      peer.on("call", (call) => {
        call.answer(stream);
        call.on("stream", (remoteStream) => {
          setStudentStream(remoteStream);
          if (isDisconnectingRef.current) {
            let message = "Student Connection has been restored";
            enqueueSnackbar(message, { variant: "info" });
          }
          isDisconnectingRef.current = false;
          setIsDisconnecting(false);
          setIsCallAccepted(true);
          setLastCallID(_id);
          if (!is_paused) {
            setIsPaused(false);
          }
          if (user.is_muted) {
            setIsMuted(true);
          }
        });
      });

      peer.on("error", (err) => {
        let logData = {
          user_info: userInfo,
          info_obj: err,
        };
        socket.emit("activity-log", logData);
        console.error("PeerJS Error:", err);
      });
    }
  };

  const handleEndCall = () => {
    let event = "call:ended";
    socket.emit(event, {
      to: callUser,
      from: userInfo._id,
      last_call_id: lastCallID,
    });
  };

  const toggleScreenShare = async (is_screen_share) => {
    const stream = await handleGetSteam(is_screen_share);
    if (!stream) return;
    if (is_screen_share) {
      stream.getVideoTracks().forEach((track) => (track.enabled = true));
    }

    setIsSharedScreen(is_screen_share);
    const call = myPeer.call(studentPeerID, stream);
    if (call) {
      socket.emit("changed_media", {
        to: callUser,
        from: userInfo._id,
        last_call_id: lastCallID,
        media_type: "is_screen_shared",
        media_status: is_screen_share,
        peer_id: myPeerID,
      });
    }
    call.on("stream", (remoteStream) => {
      setStudentStream(remoteStream);
    });
  };

  const handleStopCall = ({ data }) => {
    const { last_call_id, to } = data;
    if (
      (last_call_id && last_call_id === lastCallID) ||
      data.event === "call:unavailable" ||
      to === userInfo._id
    ) {
      if (myStream) {
        myStream.getTracks().forEach((track) => track.stop());
        setMyStream(null);
        myStreamRef.current = null;
      }
      setStudentStream(null);
      setLastCallID(null);
      if (myPeer) {
        myPeer.destroy();
        setMyPeer(null);
      }
      isDisconnectingRef.current = false;
      setIsDisconnecting(false);
      setIsCallAccepted(false);
      handleStopAudio();
      setCallUser("");
      setIsPaused(true);
      setIsMuted(false);
      setMicOn(true);
      setIsSharedScreen(false);
      endedCallTune.play();
      if (data.event === "call:unavailable") {
        setIsUnavailable(true);
      }
    }
  };

  useEffect(() => {
    socket.on("incoming:call", handleIncommingCall);
    socket.on("call:ringing", handleCallRinging);
    socket.on("call:accepted", handleCallAccepted);
    socket.on("call:declined", handleStopCall);
    socket.on("call:ended", handleStopCall);
    socket.on("call:no_answered", handleStopCall);
    socket.on("call:canceled", handleStopCall);
    socket.on("permissions_error", handleStopCall);
    socket.on("call:unavailable", (data) => handleStopCall({ data }));
    socket.on("changed_media", handleChangeMedia);

    return () => {
      socket.off("incoming:call", handleIncommingCall);
      socket.off("call:ringing", handleCallRinging);
      socket.off("call:accepted", handleCallAccepted);
      socket.off("call:declined", handleStopCall);
      socket.off("call:ended", handleStopCall);
      socket.off("call:no_answered", handleStopCall);
      socket.off("call:canceled", handleStopCall);
      socket.off("permissions_error", handleStopCall);
      socket.off("call:unavailable", (data) => handleStopCall({ data }));
      socket.off("changed_media", handleChangeMedia);
    };
  }, [socket, myPeer, myStream, lastCallID, studentStream, pathname]);

  useEffect(() => {
    let timeoutId;
    const callDisconnecting = (data) => {
      let { last_call_id } = data;
      if (last_call_id === lastCallID) {
        isDisconnectingRef.current = true;
        setIsDisconnecting(true);
        timeoutId = setTimeout(() => {
          if (isDisconnectingRef.current) {
            handleEndCall();
          }
        }, 30000);
      }
    };

    if (!isDisconnectingRef.current) {
      clearTimeout(timeoutId);
    }
    socket.on("call-disconnecting", callDisconnecting);
    return () => {
      socket.off("call-disconnecting", callDisconnecting);
      clearTimeout(timeoutId); // Clear the timeout on unmount
    };
  }, [lastCallID, isDisconnectingRef.current, pathname]);

  useEffect(() => {
    if (startedMeeting) {
      handleRejoin();
    }
  }, [startedMeeting]);

  const collection = {
    myPeer,
    isIncomingCall,
    callUser,
    isCallAccepted,
    startCallTune,
    studentStream,
    lastCallID,
    myStream,
    isPaused,
    isMuted,
    micOn,
    isDisconnecting,
    isSharedScreen,
    isUnavailable,
    selectedUser,
    setMicOn,
    setCallUser,
    handleStartCall,
    setIsIncomingCall,
    handleStopAudio,
    setLastCallID,
    toggleScreenShare,
    setIsUnavailable,
  };

  return (
    <CreateClassRoom.Provider value={collection}>
      {children}
    </CreateClassRoom.Provider>
  );
}
