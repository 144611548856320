import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomDrawer from "../../components/GeneralComponents/CustomDrawer";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";
import AddOrUpdateFees from "../../components/FeesManagement/AddOrUpdateFees";
import {
  delete_fees_request_api,
  fees_management_list_api,
} from "../../DAL/FeesManagement/FeesManagement";
import { s3baseUrl } from "../../config/config";
import {
  currency_sign,
  show_proper_words,
  textCopyToClipboard,
} from "../../utils/constant";
import StudentsTable from "../../components/FeesManagement/StudentsTable";
import ComponentPopup from "../../components/GeneralComponents/ComponentPopup";

export default function FeesManagement() {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formType, setFormType] = useState("ADD");
  const [feesList, setFeesList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
    setFormType("ADD");
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setSelectedObject();
  };

  const handleMenu = (row) => {
    const MENU_OPTIONS = [
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleEdit,
      },
    ];
    if (row.fee_status === "pending") {
      MENU_OPTIONS.push({
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      });
    }
    return MENU_OPTIONS;
  };

  const get_fees_list = async () => {
    const result = await fees_management_list_api();
    if (result.code === 200) {
      const data = result.fee_requests.map((request) => {
        let guardian_name = "";
        if (request.created_for?.name) {
          let guardian_email = request.created_for.email;
          guardian_name =
            request.created_for.name + " (" + guardian_email + ")";
        }

        let table_avatar = {
          src: s3baseUrl + request.receipt_slip,
          alt: "Receipt Slip",
        };
        return { ...request, table_avatar, guardian_name };
      });
      setFeesList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleEdit = (data) => {
    setSelectedObject(data);
    setFormType("EDIT");
    handleOpenDrawer();
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await delete_fees_request_api(selectedObject._id);
    if (result.code === 200) {
      const data = feesList.filter((fee) => fee._id !== selectedObject._id);
      setFeesList(data);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleClick = (row) => {
    setSelectedObject(row);
    setOpenDetail(true);
  };

  const handleCopyLink = async (row) => {
    const isCopied = await textCopyToClipboard(row.payment_link);
    if (isCopied) {
      enqueueSnackbar("Link Copied", { variant: "success" });
    } else {
      enqueueSnackbar("Copying Error", { variant: "error" });
    }
  };

  const TABLE_HEAD = [
    { id: "action", label: "Action", type: "action" },
    { id: "number", label: "#", type: "number" },
    {
      id: "guardian_name",
      label: "Guardian",
      handleClick: handleClick,
      className: "fees-list-label",
    },
    {
      label: "Total Fee",
      renderData: (row) => (
        <div>{currency_sign(row.currency) + row.amount.toFixed(2)}</div>
      ),
    },
    {
      label: "Charges(2.9%)",
      renderData: (row) => (
        <div>
          {currency_sign(row.currency) + row.payment_charges.toFixed(2)}
        </div>
      ),
    },
    {
      label: "Total Amount",
      renderData: (row) => (
        <div>
          {currency_sign(row.currency) +
            (row.amount + row.payment_charges).toFixed(2)}
        </div>
      ),
    },
    {
      id: "month",
      label: "Month",
      className: "text-capitalize",
    },
    {
      id: "year",
      label: "Year",
    },
    {
      label: "Copy Link",
      renderData: (row) => {
        return (
          <button
            className="payment-link-copy"
            onClick={() => handleCopyLink(row)}
          >
            Copy Link
          </button>
        );
      },
    },
    {
      id: "fee_status",
      label: "Fee Status",
      renderData: (row) => {
        let fee_status = row.fee_status;
        let class_name = "";
        if (fee_status === "pending") {
          class_name = "bg-danger";
        } else if (fee_status === "submitted") {
          class_name = "bg-primary";
        }
        return (
          <span className={`fee-status ${class_name}`}>
            {show_proper_words(fee_status)}
          </span>
        );
      },
    },

    {
      id: "table_avatar",
      label: "Receipt Slip",
      type: "thumbnail",
      show_online: true,
      className: "reduce-cell-padding",
    },
  ];

  useEffect(() => {
    get_fees_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  const detail_table = {
    students: selectedObject.students,
    currency: selectedObject.currency,
    payment_charges: selectedObject.payment_charges,
    total_amount: selectedObject.amount,
  };

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="d-flex flex-column flex-md-row justify-content-between light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Fees Management</h2>
                </div>
                <div className="add--button mb-3 text-end">
                  <button onClick={handleOpenDrawer}>Add Fee Request</button>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={feesList}
                handle_menus={handleMenu}
              />
            </div>
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={isOpenDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${formType === "EDIT" ? "Update" : "Add"} Fee Request`}
        componentToPassDown={
          <AddOrUpdateFees
            formType={formType}
            onCloseDrawer={handleCloseDrawer}
            setFeesList={setFeesList}
            selectedObject={selectedObject}
          />
        }
      />
      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this fee request?"}
        handleAgree={handleDelete}
      />
      <ComponentPopup
        openPopup={openDetail}
        setOpenPopup={setOpenDetail}
        title="Fee Detail"
        paperClass="fees-detail-popup"
        componentToPassDown={
          <StudentsTable handleClose={handleCloseDetail} {...detail_table} />
        }
      />
    </>
  );
}
