import { invokeApi } from "../../bl_libs/invokeApi";

export const activity_logs_list_api = async () => {
  const requestObj = {
    path: `api/activity_logs`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
