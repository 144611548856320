const get_icon = (name) => {
  return <i className={`nav-icon ${name}`}></i>;
};

const sidebarConfig = (userInfo) => {
  let sidebarMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: get_icon("fas fa-tachometer-alt"),
    },
    {
      title: "Students Reports",
      path: "/student-reports",
      icon: get_icon("fa-solid fa-list"),
    },
    {
      title: "Activity Logs",
      path: "/activity-logs",
      icon: get_icon("fa-solid fa-list"),
    },
    {
      title: "Class Room",
      icon: get_icon("fas fa-users"),
      child_options: [
        {
          title: "Class Chats",
          path: "/class-chat",
          icon: get_icon("fas fa-users"),
        },
        {
          title: "Class Calls",
          path: "/class-calls",
          icon: get_icon("fas fa-users"),
        },
      ],
    },
    {
      title: "Members",
      icon: get_icon("fas fa-users"),
      child_options: [
        {
          title: "Clients",
          path: "/clients",
          icon: get_icon("fas fa-users"),
        },
        {
          title: "Associates",
          path: "/associates",
          icon: get_icon("fas fa-users"),
        },
        {
          title: "Students",
          path: "/students",
          icon: get_icon("fas fa-user-graduate"),
        },
        {
          title: "Teachers",
          path: "/teachers",
          icon: get_icon("fas fa-chalkboard-teacher"),
        },
      ],
    },
    {
      title: "Web Pages",
      icon: get_icon("fa-regular fa-file-lines"),
      child_options: [
        {
          title: "Templates Pages",
          path: "/template-pages",
          icon: get_icon("fa-regular fa-file-lines"),
        },
        {
          title: "Pages",
          path: "/web-pages",
          icon: get_icon("fa-regular fa-file-lines"),
        },
      ],
    },

    {
      title: "Students Related",
      icon: get_icon("fas fa-user-graduate"),
      child_options: [
        {
          title: "Leave Requests",
          path: "/leave-requests",
          icon: get_icon("fa-solid fa-person-walking-arrow-right"),
        },
        {
          title: "Fees Management",
          path: "/fees-management",
          icon: get_icon("fa-solid fa-comment-dollar"),
        },
      ],
    },
    {
      title: "Reading Material",
      icon: get_icon("fas fa-book-reader"),
      child_options: [
        {
          title: "Lesson Books",
          path: "/lesson-books",
          icon: get_icon("fas fa-book-reader"),
        },
        {
          title: "Prayer Lessons",
          path: "/prayer-lessons",
          icon: get_icon("fas fa-book-reader"),
        },
        {
          title: "Training Lesson",
          path: "/training-lesson",
          icon: get_icon("fa-solid fa-globe"),
        },
      ],
    },
    {
      title: "Announcements",
      icon: get_icon("fas fa-bullhorn"),
      child_options: [
        {
          title: "Templates",
          path: "/announcement-templates",
          icon: get_icon("fas fa-bullhorn"),
        },
        {
          title: "Announcements",
          path: "/announcements",
          icon: get_icon("fas fa-bullhorn"),
        },
      ],
    },
    {
      title: "Extras",
      icon: get_icon("fa-regular fa-file-lines"),
      child_options: [
        {
          title: "Personal Notes",
          path: "/personal-notes",
          icon: get_icon("fa-solid fa-book"),
        },
        {
          title: "To Do Lists",
          path: "/to-do-lists",
          icon: get_icon("fa-solid fa-list"),
        },
      ],
    },
  ];

  // Correct the conditional check
  // if (userInfo.role === "supper_admin") {
  //   sidebarMenus[3].child_options.push({
  //     title: "Users",
  //     path: "/admin-users",
  //     icon: get_icon("fas fa-user-friends"),
  //   });
  // }

  return sidebarMenus;
};

export default sidebarConfig;
